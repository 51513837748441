import React from 'react'

const videoSection = ({ system, elements }) => (
  <section id={system.codename} className="main style1">
    <div className="grid-wrapper">
      <div className="col-2"/>

      {
        <div className="col-8" style={{textAlign:'center'}}>
        {
          /*
          <p>
          我們提供一站式服務平台，專為各中小企業度身訂造各種大小型程式開發及創意設計相關項目。​服務包括：手機應用程式開發、系統開發、網頁設計及開發等。針對不同類型行業類型提供一站式的IT服務解決方案。
          </p>
          */
        }

        {
          <h2>關於我們</h2>
        }
        {
          <p>
          成立於2020年，致力於提供一站式數碼行銷整合方案。以「多元數碼行銷創意推廣，科技應用研發推廣，媒體資源分析」三線發展，多年來服務客戶超過100家。
          </p>
        }
        {
          <h2>我們的使命</h2>
        }
        {
          <p>
          每間公司背後都有一個關於夢想的故事，織就城市的一點光。我們助您燃點您的夢想，提供綜合網上策劃廣告方案。由構思到實現，由網上搜尋到社交媒體，我們都陪著您。讓更多人了解您的夢想，讓它更光更亮。
          </p>
        }
        <br/>
        {
          <h3>服務</h3>
        }
        {
          <h2>數碼搜尋推廣</h2>
        }
        {
          <p>
          作為多個搜尋網站的合作伙伴，我們會為您度身訂造最合適的廣告投放方案，文字，圖片甚至影片，有效地最大化您的資源。
          </p>
        }
        {
          <h2>社交媒體推廣</h2>
        }
        {
          <p>
          為您制作最具創意，最適合您的廣告推廣方案。 例如：facebook 專頁管理，貼文，製造話題，製做小遊戲，吸引客戶增加LIKE數及公司知名度等。
          </p>
        }
        {
          <h2>客戶開發</h2>
        }
        {
          <p>
          為客戶在各大搜尋器上設立最適合客戶的關鍵字，讓您的潛在客戶能隨時隨地接收到您的訊息。 在吸納新客戶之餘，我們會為客戶隨時監察及管理您的廣告效益，最時優化以達到效益最大化目標。
          </p>
        }

        {
          <h2>度身訂做</h2>
        }
        {
          <p>
          我們擁有專業開發團隊，無論在廣告設計，網站開發，APP開發，動畫設計，行銷策劃，以至關鍵字管理。 在每個細節上我們專業的團隊會為您製作一個為您專屬方案。 我們目標就是要為您提供最優質的服務，最大化您的效益，我們的客戶都是陪伴著我們一起成長，見證著每一個理程碑。
          </p>
        }
        {
          <h2>強大支持</h2>
        }
        {
          <p>
          我們嚴選多種網站內容設計或模板搭配方案。 每個設計款式都能突顯您們的尊貴形象。我們擁有多年經驗的專業介面設計，務求為您提供最有效最創眼的方案。
          </p>
        }
        </div>
      }
      <div className="col-2"/>
      {
        /*
        <div className="col-6">
          <header className="major">
            <h3>專業企業生意買賣、業務支援顧問、IT FIN-TECH 顧問與支援</h3>
          </header>
          <p>致力為面對貸款難題的各界中小企及個人客戶，提供專業的企業生意買賣，業務支援的顧問服務</p>
        </div>
        */
      }
      {
        /*
        <div className="col-6">
          <header className="major">
            <h3>服務宗旨</h3>
          </header>
          <p>「不成功，不收費」 、「不含隱藏收費」及「所有資料，絕對保密」</p>
          {
            // <p>提供完善解決方案，滿足客戶需求，建立可信賴的長遠合作關系</p>
          }
        </div>
        */
      }

    </div>
  </section>
)

export default videoSection
