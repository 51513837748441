import React from 'react'

import BlogPostThumbnail from './BlogPostThumbnail'

const threeColumnsSection = ({ elements }) => {
  const blogElements = [
    /*
    {
      key: 1,
      blogname: '/tvp',
      title: '科技券 資助計劃 (TVP)',
      summary: '科技券計劃旨在資助本地中小型企業(下稱「中小企」)使用科技服務和方案，以提高生產力或升級轉型。計劃由創新及科技基金提供，由2020年4月1日起，每間公司可得到最高60萬支助！欲了解更多有關科技券計劃，請聯絡我們'
    },
    */
    {
      key: 2,
      blogname: '/bud',
      title: 'BUD專項基金(BUD)',
      summary: 'BUD專項基金旨在資助個別香港企業推行有關發展品牌、升級轉型和拓展營銷的項目，從而提升他們在內地市場及與香港簽署自貿協定的經濟體的競爭力，促進他們在內地及自貿協定市場的發展。每次申請一個項目最高可得到100萬支助！ 每間公司累計資助上限為港幣400萬元。欲了解更多有關BUD專項基金，請聯絡我們'
    },
    {
      key: 3,
      blogname: '/citf',
      title: '建造業創新及科技基金 (CITF)',
      summary: '香港特別行政區政府發展局（發展局）獲批撥款港幣10億元，在2018年10月成立建造業創新及科技基金（基金）。發展局委託建造業議會（議會）為基金執行伙伴。基金鼓勵廣泛採用創新建築方法及科技，促進生產力、提高建造質素、改善工地安全及提升環保效益。欲了解更多有關建造業創新及科技基金 (CITF)，請聯絡我們'
    }
  ]

  const blogThumbnails = blogElements.map(blogPost => (
    <BlogPostThumbnail
      {...blogPost}
      detailValue='MORE'
    />
  ))
  return null;
  return (
    <section id="three" className="main style1 special">
      <div className="grid-wrapper">
        <div className="col-12">
          <header className="major">
            <h2>政府資助計劃</h2>
          </header>
          <div
            dangerouslySetInnerHTML={{ __html: '<p>我們協助各界中小企申請政府資助,提供專業諮詢服務,為客戶掃除商業道路上疑惑, 支援企業業務上的發展。</p>' }}
          />
        </div>


        {
            blogThumbnails
        }

        <div className="col-12" style={{marginTop:40}}>
          <h3>中小企融資擔保計劃 (SME Financing Guarantee Scheme)</h3>
            <p style={{height:"50%"}}>中小企融資擔保計劃「中小企融資擔保計劃」﹙「本計劃」﹚於2011年1月1日由香港按揭證券有限公司 ﹙「按揭證券公司」﹚ 推出。自2018年5月1日起，本計劃之業務已轉移至香港按證保險有限公司 ﹙「按證保險公司」﹚ 並由其繼續經營。按證保險公司為一所由按揭證券公司全資擁有的附屬公司。本計劃旨在協助本地中小型企業﹙「中小企業」﹚及非上市企業﹙統稱「該等企業」﹚從參與計劃的貸款機構﹙「貸款機構」﹚取得融資，應付業務需要，並在急速轉變的營商環境中，提升生產力和競爭力。在本計劃下，按證保險公司可為合資格企業並獲貸款機構批核的貸款提供五成、六成或七成的信貸擔保。欲了解更多資料，請聯絡我們</p>
            <ul className="actions">
              <li>
                <a href="/sme" target="_blank" className="button">
                  更多
                </a>
              </li>
            </ul>
        </div>
      </div>
    </section>
  )
}

export default threeColumnsSection
