import React from 'react'

const blogPostThumbnail = ({ blogname, title, summary}) => (
  <div className="col-6">
    <span className="image fit">
    </span>
    <h3>{title}</h3>
    <p style={{height:"50%"}}>{summary}</p>
    <ul className="actions">
      <li>
        <a href={blogname} target="_blank" className="button">
          更多
        </a>
      </li>
    </ul>
    </div>
)

export default blogPostThumbnail
