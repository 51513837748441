import React from 'react'
import logo from '../assets/images/SealCave-logo.png'

const HeaderSection = ({ elements }) => (
  <section id="header">
    <div className="inner">
      <img src={logo}></img>
      <h1>Seal Cave Limited</h1>
      <ul className="actions">
        <li>
          <a href="" className="button scrolly">
            Discover
          </a>
        </li>
      </ul>
    </div>
  </section>
)

export default HeaderSection
