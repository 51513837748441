import React from 'react'

const featuresSection = ({ elements }) => {
  const icons = elements.icons.value.map((icon, index) => (
    <li key={icon.system.id}>
      <span
        className={`icon style${index + 1} major ${icon.elements.code.value}`}
      />
    </li>
  ))

  const items =[
    {
      key:1,
      title:'手機應用(APP)開發',
      content:'我們為各行各業提供多種不同的手機APP開發服務, 我們會仔細聽取客戶需求, 分析客戶的業務發展,度身訂做一個合適客戶使用的APP。',
    },
    {
      key:1,
      title:'網站開發',
      content:'購物網站開發，公司網站開發，內部系統開發，一應俱備。 配合響應式設計, 完美合適市面上各式手機尺寸。',
    },
    {
      key:1,
      title:'人性化介面設計',
      content:'我們專業的設計團隊量身度造的圖像化介面,使用戶能夠更直感,快速地適應你的軟件產品。',
    },
  ]

  const RenderObj =({data})=>{
    console.log(data)
    return(
      <div className="col-4" style={{
        border:'solid 1px',
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'column',
        padding:20,
        borderRadius:10,
        margin:20,
        backgroundColor:'#00000088'
      }}>
      <div style={{
        // border:'solid 1px',
        display:'flex',
        justifyContent:'center',
      }}>
        <h3>{data.title}</h3>
      </div>
      <div><p>{data.content}</p></div>
      </div>
    )
  }

  const renderObjs=items.map(data=>(
    <RenderObj data={data}/>
  ))
  return null;
  return (
    <section id="two" className="main style2">
      <div className="grid-wrapper">
        {
          // <div className="col-12" style={{border:'solid 1px',
          // // display:'flex', flexDirection:'row' , justifyContent:'center',alignItems:'top'
          // }}>
          // </div>
        }

        {
          renderObjs
        }
      </div>
    </section>
  )

  return(
    <section id="two" className="main style2">
      <div className="grid-wrapper">
        <div className="col-12">
          <header className="major">
            <h2>我們的服務</h2>
          </header>
          <header>
            <h3>成立有限公司</h3>
          </header>
          <div><p>Sealcave為您提供一站式成立香港有限公司註冊服務</p></div>
          <header>
            <h3>成立無限公司</h3>
          </header>
          <div>
            <p>無限公司的東主或合夥人需負的責任並無上限，經營風險由東主或合夥人悉數承擔，公司的債務亦由東主或合夥人全數負責；債權人有權向法院申請查封及出售東主或合夥人私人名下財產。各創業人士應在創業前謹慎考慮以成立有限公司或成立無限公司經營業務。 根據商業登記條例，申請者必需是「香港居民身分證」或「香港永久性居民身分證」持有人。</p>
            <p>如申請者持有「香港居民身分證」，請提供有關的入境簽證或入境標籤。如該簽證或標籤已註明其身分「不得開辦或參與任何業務」，本公司將不會接納其註冊無限公司的申請。</p>
          </div>

          {
            /*
            <header>
              <h3>虛擬辦公室服務</h3>
            </header>
            // */
          }
          {
            /*
            <div>
              <p>Sealcave為您提供一站式成立香港有限公司註冊服務，一個價錢絕無其他隱藏收費；毋須親臨本公司，最快只需1-2個工作天辦妥。建立虛擬辦公室助您節省高昂租金。</p>
            </div>
            // */
          }
          {
            /*
            <header>
              <h3>公司秘書服務</h3>
            </header>
            */
          }
          {
            /*
            <div>
              <p>Sealcave為您提供一站式公司秘書服務</p>
            </div>
            */
          }
          {
            /*
            <header>
              <h3>政府支援</h3>
            </header>
            */
          }
          {/*
            <div>
              <p>創新科技署推出「科技券計劃」目的是幫助中小企公司，透過科技服務解決企業在於技術/程式/運作上遇到的問題，幫助提高公司生產效率、簡化運作流程、整合系統數據等等，從而提升香港企業自身的競爭力，促進社會發展。</p>
              <p>資料來源: 創新科技署 <a target="_blank" href="https://www.itf.gov.hk/l-tc/TVP.asp">https://www.itf.gov.hk/l-tc/TVP.asp</a></p>
            </div>
            */
          }
        </div>
      </div>
    </section>
  )
}
export default featuresSection
